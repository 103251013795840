import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import CarNumberInput from '@components/carfax/CarNumberInput';
import HistoryPlate from '@components/carfax/HistoryPlate';
import MainCompanyCS from '@components/carfax/MainCompanyCS';
import Header from '@components/carfax/Header';
import CARFAX_LOGO from '@public/images/carfax-logo.png';

import MobileHeader from '@components/carfax/MobileHeader';
import GAHead from '@components/carfax/GAHead';
import { useIsMobile } from '@service/hooks/useIsMobile';
import TestCarNumberInput from '@components/carfax/TestCarInput';

const CarCheckMain = () => {
  const [logoRatio, setLogoRatio] = useState<number>(1);

  const isMobile = useIsMobile();

  const GA_EVENT = `
  gtag('event', 'view_carfax_home', {
    event_label: '카팩스 메인페이지 뷰'
  });
  `;

  return (
    <>
      <GAHead eventCode={GA_EVENT} />
      <Container>
        <Wrapper isMobile={isMobile}>
          {isMobile ? <MobileHeader /> : <Header />}
          <TestCarNumberInput isMobile={isMobile} />
          {/* <CarNumberInput isMobile={isMobile} /> */}
          {/* <HistoryPlate isMobile={isMobile} /> */}
        </Wrapper>
      </Container>
      <MainCompanyCS isMobile={isMobile} />
    </>
  );
};

export default CarCheckMain;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
`;

const Wrapper = styled.div`
  height: ${({ isMobile }) => (isMobile ? 72 : 250)}px;
  width: 100%;
  background-color: #555555;
`;
